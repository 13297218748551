.my-button {
    background-color: var(--main-color);
    /*color: #fff;*/
    color: var(--g-color-text-brand-contrast);
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    padding: 6px 12px;
    font-size: 16px;
    border: none;
    border-radius: 0.375rem;
    cursor: pointer;
    transition: background-color 0.15s ease;
    line-height: 1.5;
}

.my-button:disabled {
    color: var(--g-color-text-brand-contrast);
    background-color: var(--main-color-dark);
}

.my-button:hover {
    color: var(--g-color-text-brand-contrast);
    background-color: var(--main-color-dark);
}

.add-button_max-width {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
}

.add-button_max-width .g-button__text {
    padding-left: 0;
}

.link-like-button {
    background: none !important;
    border: none;
    padding: 0 !important;
    color: var(--g-color-text-link);
    text-decoration: underline;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.link-like-button:hover {
    color: var(--g-color-text-link-hover);
}

.link-like-button:disabled {
    color: var(--g-color-text-hint) !important; /* Добавьте этот стиль */
    text-decoration: none; /* Уберите подчеркивание */
    cursor: default;
}

.g-button.start-icon-button {
    text-align: start;
}

.icon-like-button {
    background: none !important;
    border: none;
    padding: 0 !important;
}

.icon-like-button:not(:disabled) {
    color: var(--g-color-text-link);
    cursor: pointer;
}

.icon-like-button:not(:disabled):hover {
    color: var(--g-color-text-link-hover);
}

.icon-like-button:not(:disabled):active {
    transform: scale(0.95); /* Уменьшаем размер кнопки при нажатии */
}

.reset-style-button {
    background: none !important;
    border: none;
    padding: 0 !important;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: inherit;
}