form > div:not(:last-child) {
    margin-bottom: 1rem;
}

.form-explain > div:not(:last-child) {
    margin-bottom: 1.5rem;
}

.line-btn-group {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.form-check {
    min-height: auto;
    margin-bottom: 0;
}

.form-label, .form-check-label {
    font-size: 16px;
}

.form-label.required:after, .required .form-check-label:after {
    content: "*";
}

.form-check-input:checked {
    background-color: var(--main-color);
    border-color: var(--main-color);
}

.input-group, .react-international-phone-input-container, .form-control-sm {
    border-radius: 6px;
}

.input-group:focus-within:not(:has(.is-invalid)),
.form-control:focus:not(.input-group .form-control),
.form-check-input:focus,
.form-select:focus,
.react-international-phone-input-container:focus-within,
.message-input-container textarea:focus-visible {
    border-color: var(--main-color-light);
    box-shadow: 0 0 2px 3px var(--main-color-light);
}

.input-group:focus-within:not(:has(.is-invalid)) > *,
.react-international-phone-input-container:focus-within button,
.react-international-phone-input-container:focus-within input {
    border-color: var(--main-color-light);
    box-shadow: none;
}

.form-control.is-invalid {
    border-color: var(--g-color-base-danger-heavy);
    background-image: none;
}

.input-group:has(.is-invalid) + .invalid-feedback {
    display: block;
}

.is-invalid .rti--container ~ .invalid-feedback {
    display: block;
}

.invalid-feedback {
    color: var(--g-color-base-danger-heavy);
}

.input-group:focus-within:has(.is-invalid),
.form-control.is-invalid:focus:not(.input-group .form-control),
.form-check-input.is-invalid:focus,
.form-select.is-invalid:focus {
    border-color: var(--g-color-base-danger-heavy);
    box-shadow: 0 0 2px 3px var(--g-color-base-danger-light);
}

.input-group:focus-within:has(.is-invalid) > * {
    border-color: var(--g-color-base-danger-heavy);
    box-shadow: none;
}

.form-control.is-invalid ~ .btn-show-password {
    border-color: var(--g-color-base-danger-heavy);
}

.css-b62m3t-container:has([aria-invalid="true"]) + .invalid-feedback {
    display: block;
}

.bmm-select__control {
    border-color: #dee2e6 !important;
    border-radius: 6px !important;
}

.bmm-select__control.bmm-select__control--is-focused {
    border-color: var(--main-color-light) !important;
    box-shadow: 0 0 2px 3px var(--main-color-light) !important;
}

.bmm-select__control:has([aria-invalid="true"]) {
    border-color: var(--g-color-base-danger-heavy) !important;
}

.bmm-select__control.bmm-select__control--is-focused:has([aria-invalid="true"]) {
    box-shadow: 0 0 2px 3px var(--g-color-base-danger-light) !important;
}

.bmm-select__value-container--is-multi {
    gap: 0.5rem !important;
    padding: 5px 8px !important;
}

.bmm-select__single-value,
.bmm-select__placeholder {
    font-size: 16px !important;
}

.bmm-select__placeholder {
    color: var(--bs-border-color) !important;
}

.bmm-select__input-container {
    cursor: text !important;
    font-size: 16px !important;
}

.bmm-select__multi-value {
    background: var(--main-color-light) !important;
    border-radius: 6px !important;

    gap: 0.25rem !important;

    margin: 0 !important;
    padding: 0.15rem 0.5rem !important;
}

.bmm-select__multi-value__label {
    font-size: 13px !important;
    padding: 0 !important;
}

.bmm-select__multi-value__remove {
    padding: 0 !important;
}

.bmm-select__multi-value__remove:hover {
    background: none !important;
}

.bmm-select__indicator {
    cursor: pointer !important;
}

.bmm-select__multi-value__remove .css-tj5bde-Svg {
    height: 16px !important;
    width: 16px !important;
    fill: #666 !important;
}

.bmm-select__multi-value__remove .css-tj5bde-Svg:hover {
    fill: var(--g-color-base-danger-heavy) !important;
}

.bmm-select__menu {
    top: 92% !important;
}

.bmm-select__menu-list {
    max-height: 150px !important;
}

.bmm-select__option {
    font-size: 16px !important;
}

.bmm-select__option.bmm-select__option--is-focused {
    background-color: var(--main-color-light);
}

.bmm-select__option.bmm-select__option--is-selected {
    background-color: var(--main-color);
}

.custom-option .user-card {
    display: flex;
    align-items: center;
    line-height: normal;
}

.custom-option:hover {
    background-color: var(--main-color-light);
}

.react-international-phone-input-container .react-international-phone-input {
    font-size: 16px;
    width: 100%;
    z-index: 1;
}

.react-international-phone-input-container .react-international-phone-input,
.react-international-phone-country-selector-button {
    height: 38px;
    border-color: #dee2e6;
}

.person-card-in-form .invalid-feedback {
    display: block;
}

.application-card {
    background: #f9f9f9;
    border-radius: 6px;
    box-sizing: border-box;
    margin: 1rem 0;
    padding: 1rem;
    position: relative;
}

.application-card .cursor-drag {
    margin-right: 1rem;
    touch-action: none;
}

.application-card .application-card__action svg {
    color: #a9a9a9;
}

.application-card .application-card__action.delete-file-btn svg {
    color: #000000D9;
    margin-left: -15px;
}

.application-card .g-button_view_normal-contrast:before {
    background-color: #f9f9f9;
}

.application-card .project-card__title {
    font-size: 1.125rem;
    font-weight: 500;
    margin-top: 0;
}

.application-card .single-line-text {
    line-height: 20px;
    -webkit-line-clamp: 2 !important;
}

@media (min-width: 792px) {
    .application-card .single-line-text {
        margin-right: 40px;
        -webkit-line-clamp: 1 !important; /* Установите количество строк для отображения */
    }
}

.application-card .test-file-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
}

.application-card .test-file-section .test-solution {
    display: flex;
}

.application-card .filename {
    max-width: 150px; /* Задаете максимальную ширину */
    overflow: hidden;
    text-overflow: ellipsis; /* Добавляет многоточие для обрезанного текста */
    white-space: nowrap; /* Запрещает перенос текста */
}

@media (max-width: 991px) {
    .application-card .test-file-section {
        flex-direction: column;
        align-items: start;
    }

    .application-card .test-file-section .test-file {
        margin-top: 10px;
    }
}

@media (max-width: 449px) {
    .application-card .test-file-section .test-solution {
        flex-direction: column;
    }
}

.min-width-50px {
    min-width: 50px;
}

.form__card {
    margin-top: 1rem;
}

.is-invalid ~ div > div > .invalid-feedback {
    display: block;
}

.selection-cv .selection-text {
    white-space: pre-line;
    padding-left: 20px;
}

.bmm-select__control .react-select-icon {
    color: rgba(0, 0, 0, 0.3);
    margin-left: 10px;
}

.inline-form-control {
    display: initial;
    width: unset;
    margin: 0 4px;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.icon-loading {
    animation: spin 1s linear infinite;
}

.without-appearance input::-webkit-outer-spin-button,
.without-appearance input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0;
    -moz-appearance: textfield;
}

.icon-fade {
    transition: opacity 0.5s ease-in-out;
    opacity: 1;
}

.icon-hidden {
    opacity: 0;
}