.bmm-modal {
    max-width: 400px;
    padding: 20px;
    text-align: center;
    font-size: 18px;
    line-height: 130%;
}

.bmm-modal_form {
    text-align: left;
    width: 400px;
    line-height: 100%;
}

.bmm-modal_chat {
    text-align: left;
    max-width: 800px;
}

@media (max-width: 440px) {
    .bmm-modal_form {
        max-width: none;
        width: 100%;
    }
}

.modal-buttons {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    gap: 10px;
}