.callout {
    border-left: 4px solid;
    padding: 1rem;
    margin: 1rem 0;
    border-radius: 4px;
}

.callout-note {
    border-color: #007bff; /* Синий цвет для заметок */
    background-color: #e7f0ff;
}

.callout-warning {
    border-color: #ffc107; /* Желтый цвет для предупреждений */
    background-color: #fff3cd;
}

.callout-danger {
    border-color: #dc3545; /* Красный цвет для опасностей */
    background-color: #f8d7da;
}

.callout-title {
    font-weight: bold;
}

.callout-content {
    margin-top: 0.5rem;
}

.page-title, .bmm-markdown-text h1 {
    color: black;
    font-size: 3.125rem;
    font-weight: 700;
    line-height: 100%;

}

.bmm-markdown-text h6 {
    margin-top: 0;
    margin-bottom: 0;
}

.bmm-markdown-text h3 {
    color: var(--main-color);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 130%;
}

.bmm-markdown-text em {
    color: var(--main-color);
    font-style: normal;
}

@media (max-width: 767px) {
    .page-title, .bmm-markdown-text h1 {
        font-size: 2.5rem;
    }
}

.bmm-period {
    color: var(--main-color);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 110%;
    margin-bottom: 1rem;
}

.bmm-important-info, .bmm-markdown-text >h1>a {
    color: black;
    display: inline-block;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 110%;
}

.bmm-markdown-text >h1>a:hover {
    color: var(--g-color-text-link-hover);
}

.info-icon {
    color: var(--main-color);
    margin-left: 5px;
}

@media (min-width: 992px) {
    .bmm-period {
        margin-bottom: 2rem;
    }
}

.bmm-title, .bmm-markdown-text h2:not(.accordion-header){
    font-size: 2.1875rem;
    font-weight: 600;
    line-height: 100%;
    margin-bottom: 1rem;
    margin-top: 2.5rem;
}

.bmm-title__smaller {
    font-size: 28px;
}

@media (max-width: 767px) {
    .bmm-title {
        font-size: 2rem;
    }
}

.bmm-subtitle {
    color: var(--main-color);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 130%;
}

.bmm-text, .bmm-markdown-text p, .bmm-markdown-text li {
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 150%;
    text-align: justify;
}

.data-footnote-backref{
    display: none;
}

.bmm-markdown-text .bmm-markdown-p {
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 150%;
    text-align: justify;
    margin-bottom: 1rem;
    margin-top: 0;
}

@media (max-width: 767px) {
    .bmm-text {
        text-align: inherit;
    }
}

.bmm-text_bold {
    font-size: 18px;
    font-weight: 400;
    line-height: 110%;
}

.bmm-text_gray {
    color: var(--gray-color);
}

.bmm-text_lightgray {
    color: var(--g-color-base-generic-medium-hover)
}

.bmm-text_align-start {
    text-align: start;
}

.carousel-img {
    width: 100%; /* По умолчанию 100% для маленьких устройств */
    /*max-width: 60%; !* Максимальная ширина 60% для средних устройств и больше *!*/
    object-fit: cover; /* Заполнение контейнера без искажения пропорций */
    display: block; /* Чтобы применять margin auto для выравнивания по центру */
    margin: auto; /* Выравнивание по центру */
}

.carousel-indicators {
    margin: 0 0 0.25rem;
}

.carousel-indicators [data-bs-target] {
    width: 5px;
    height: 5px;
    border-radius: 50%;
}

/* Медиа-запросы для различных размеров экранов */
@media (min-width: 768px) {
    .carousel-img {
        width: 60%;
    }

    .carousel-control-prev-icon, .carousel-control-next-icon {
        filter: invert(1) grayscale(100);
    }

    .carousel-indicators [data-bs-target] {
        width: 8px;
        height: 8px;
        background-color: #000;
    }
}

/*.about-accordion {*/
/*    box-shadow: 0 4px 64px rgba(19, 23, 61, .11);*/
/*}*/

.accordion-button:focus {
    box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
    color: inherit;
    background-color: inherit;
    box-shadow: none;
}

.info__card {
    width: 100%;
    padding: 20px;
}

@media (min-width: 768px) {
    .info__card {
        width: 400px;
    }
}

.quantity-info {
    font-size: 14px;
    line-height: 110%;
    opacity: .3;
    display: flex;
    justify-content: right;
}

.projects__quantity-info {
    margin-top: 10px;
}


.users__quantity-info {
    margin: 10px 0;
}

.info__title {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 110%;
    margin-bottom: 1rem;
}

.info__text {
    font-size: 14px;
    font-weight: 400;
    line-height: 110%;
}

.info-footer {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;

    font-size: 14px;
}

.avatar-upload__button {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 4px 8px;
    margin: 0 0 8px 8px;

    border-radius: 6px;
    background: #fff;
    border: 1px solid var(--bs-border-color);
}

.project-filters {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.filters-column {
    flex-direction: column;
    flex: 1 1 calc(50% - 10px);

}

.filter {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.filters-column label {
    font-size: 16px;
    margin-right: 8px;
    font-weight: bold;
    width: 85px;
}

.filters-column .g-select {
    flex-grow: 0;
    width: 200px;
}

.filters-column.wide-filters-label label {
    width: 145px;
}

.filters-column.narrow-filter-label label {
    width: 55px;
}

@media (max-width: 1199px) {
    .filters-column.narrow-filters-select .g-select {
        width: 140px;
    }

    .filters-column:nth-child(2n) .filter {
        justify-content: right;
    }

    .filters-column.narrow-filters-select .g-select {
        width: 140px;
    }
}

@media (max-width: 991px) {
    .project-filters.wide-filters-label .filters-column label {
        width: 145px;
    }

    .project-filters.middle-filters-label .filters-column label {
        width: 85px;
    }

    .filters-column {
        flex: 1 1 100%;
    }

    .filters-column .g-select {
        flex-grow: 1;
    }
}

.local-workshop-item:not(:last-child) {
    margin-bottom: 16px;
}

.bmm-popover-l {
    max-width: 400px;
}

.bmm-popover-m {
    max-width: 300px;
}

.bmm-popover-motivation {
    max-width: 40rem;
    max-height: 20rem;
    overflow-y: scroll;
}

.button-width-180 {
    width: 180px;
}

.project-info {
    display: flex;
    align-items: center;
    gap: 10px;
    /*flex-direction: column;*/
    font-size: 1.0625rem !important;
    line-height: 1.25rem !important;
    margin-top: 1rem;
}

.project__keywords {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-top: 0.625rem;
    font-size: 1.0625rem !important;
    line-height: 1.25rem !important;
    color: var(--main-color);
}

.project__keywords > div:not(:first-child)::before {
    content: '•';
    margin-right: 5px;
}

/* страница "Пользователи" http://127.0.0.1:3000/account/users */
.user-table .g-table__table {
    width: 100%;
}

.single-line-text {
    display: -webkit-box !important;
    -webkit-box-orient: vertical !important;
    -webkit-line-clamp: 1 !important; /* Установите количество строк для отображения */
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: normal !important; /* Это позволит тексту переноситься на новую строку */
    line-height: 110%;
}

.line-clamp-2 {
    -webkit-line-clamp: 2 !important;
}

.title_tooltip {
    width: 300px;
}

.search-input input {
    font-size: 16px !important;
    padding: 6px 12px !important;
    height: 36px !important;
}

.search-input svg {
    color: var(--g-color-text-dark-hint);
}

.search-input span:first-child {
    border-color: var(--bs-border-color);
    --g-text-input-placeholder-color: var(--bs-border-color);
}

.search-input span:first-child:focus-within {
    border-color: var(--main-color-light) !important;
    box-shadow: 0 0 2px 3px var(--main-color-light) !important;
}

.search-input span:first-child div {
    padding-left: 10px;
}

/* страница "Проекты"*/
.flex-container {
    display: flex;
}

.first-column {
    width: 330px; /* Ширина первого столбца */
}

.second-column {
    flex: 1; /* Второй столбец занимает все оставшееся пространство */
}

.public-project-filter_mobile {
    display: none;
}

.public-project-filter_desktop {
    margin: 1.5rem 1.5rem 0 0;
}

@media (max-width: 991px) {
    .flex-container {
        flex-direction: column; /* Столбцы становятся вертикальными на мобильных устройствах */
    }

    .first-column {
        width: 100%; /* Первый столбец занимает всю ширину на мобильных устройствах */
    }

    .public-project-filter_mobile {
        display: block;
        margin-top: 1.5rem;
    }

    .public-project-filter_desktop {
        display: none;
    }
}

.public-project-filter_mobile > .accordion-item > .public-project-filter__name > .accordion-button.collapsed::after,
.public-project-filter_mobile > .accordion-item > .public-project-filter__name > .accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16'%3E%3Cpath fill='currentColor' fill-rule='evenodd' d='M12.5 4c0 .174-.071.513-.885.888C10.8 5.263 9.538 5.5 8 5.5c-1.538 0-2.799-.237-3.615-.612C3.57 4.513 3.5 4.174 3.5 4c0-.174.071-.513.885-.888C5.2 2.737 6.462 2.5 8 2.5c1.538 0 2.799.237 3.615.612.814.375.885.714.885.888Zm-1.448 2.66C10.158 6.888 9.114 7 8 7s-2.158-.113-3.052-.34l1.98 2.905c.21.308.322.672.322 1.044v3.37c.06.012.141.021.25.021.422 0 .749-.14.95-.316.185-.162.3-.38.3-.684v-2.39c0-.373.112-.737.322-1.045l1.98-2.904ZM8 1c3.314 0 6 1 6 3a3.24 3.24 0 0 1-.563 1.826l-3.125 4.584a.354.354 0 0 0-.062.2V13c0 1.5-1.25 2.5-2.75 2.5s-1.75-1-1.75-1v-3.89a.354.354 0 0 0-.061-.2L2.563 5.826A3.242 3.242 0 0 1 2 4c0-2 2.686-3 6-3Zm-.88 12.936c-.01-.005-.014-.009-.013-.01l.013.01Z' clip-rule='evenodd'/%3E%3C/svg%3E");
    transform: none;
}

.public-project-filter .accordion-button.collapsed {
    background-color: #f9f9f9;
}

.public-project-filter .accordion-item {
    background-color: #f9f9f9;
    border-color: white;
}

.public-project-filter .accordion-button {
    font-size: 1.125rem;
    font-weight: 600;
}

.public-project-filter .accordion-body {
    padding-top: 0;
}

.public-project-filter .accordion-body_scroll {
    padding-left: 0;
}

.public-project-filter .accordion-body_scroll > div {
    padding-left: 1rem;
}

.public-project-filter .accordion-div_scroll {
    max-height: 175px;
    overflow-y: auto;
}

.public-project-filter .accordion-div_scroll::-webkit-scrollbar-track {
    background-color: #f9f9f9 !important; /* Цвет фона трека */
    border-bottom-right-radius: 6px;
}

.public-project-filter__reset-button {
    cursor: default;
    display: flex;
    justify-content: center;
    padding: 1rem 1.25rem;
}

.form-check-input[class$="-indeterminate"] {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16'%3E%3Cpath fill='%23dee2e6' fill-rule='evenodd' d='M 2.5 8 a 1 1 0 0 1 1 -1 h 9 a 0.75 0.75 0 0 1 0 2 h -9 A 1 1 0 0 1 2.5 8 Z' clip-rule='evenodd'/%3E%3C/svg%3E");
}

.selection-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.selection-title .project-card__title {
    margin: 0;
    flex: 1;
}

.selection-title a {
    min-width: 125px;
    margin-left: 20px;
}

.two-columns-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px; /* Зазор между элементами */
}

.columns-item {
    margin: 0;
}

.four-columns-container {
    display: grid;
    grid-template-columns: repeat(4, minmax(200px, 1fr));
    gap: 20px; /* Зазор между элементами */
}

@media (max-width: 1399px) {
    .four-columns-container {
        grid-template-columns: repeat(3, 1fr); /* Один столбец на маленьких экранах */
    }
}

@media (max-width: 1200px) {
    .four-columns-container {
        grid-template-columns: repeat(2, 1fr); /* Один столбец на маленьких экранах */
    }
}

@media (max-width: 991px) {
    .two-columns-container {
        grid-template-columns: 1fr; /* Один столбец на маленьких экранах */
    }

    .four-columns-container {
        grid-template-columns: repeat(1, 1fr); /* Один столбец на маленьких экранах */
    }
}

/* страница "Рефлексии" http://127.0.0.1:3000/account/reflection-review */

.template-section {
    display: flex;
    align-items: center;
    gap: 10px;

    margin-bottom: 15px;
}

/* страница "Распределение" http://127.0.0.1:3000/account/distribution */

.grade-label {
    min-width: 37px;
}

.priority-label {
    min-width: 30px;
}

.grade-label .g-label__text, .priority-label .g-label__text {
    justify-content: center;
}

.distribution-participant-list {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.distribution-participant-card {
    padding: 0.5rem;
    margin: 0;

    display: flex;
    align-items: center;
    justify-content: space-between;

    cursor: default;
}

.distribution-deleted-project-card {
    padding: 0.5rem;
    margin: 0;

    display: flex;
    align-items: center;
    justify-content: space-between;

    cursor: default;
}


.distribution-participant-card.selected {
    background-color: rgba(211, 211, 211, 0.63);
}

.distribution-participant-proj-list {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.distribution-participant-proj-card {
    padding: 8px;
    margin: 0;
    display: flex;
    align-items: center;

}

.distribution-participant-proj-card.selected {
    background-color: rgba(211, 211, 211, 0.63);
}

.line-btn-group_distribution {
    /*flex-direction: row;*/
    flex-wrap: wrap;
}

.line-btn-group_distribution > * {
    flex: 1 !important;
}

.line-btn-group_distribution button {
    flex: 1;
}

.project-card .hover-button,
.distribution-participant-card .square-list-button {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.2s linear;
}

/* Показать кнопку при наведении курсора на карточку проекта */
.project-card:hover .hover-button,
.distribution-participant-card:hover .square-list-button {
    visibility: visible;
    opacity: 1;
}

/* Убедитесь, что кнопка отображается только при наличии выбранных участников */
.project-card .hover-button:disabled {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.2s linear;
}

.custom-input-group {
    display: flex;
}

.custom-input-group .g-text-input.g-text-input_size_xl.g-text-input_pin_brick-round .g-text-input__content {
    border-start-end-radius: 10px;
    border-end-end-radius: 10px;
}

.custom-input-group .g-button__text {
    padding-right: 4px;
}

.custom-input-group .g-text-input .g-text-input__label {
    padding-inline-start: 4px;
}

.custom-input-group .g-text-input__content {
    border-color: #0000000d;
}

.custom-input-group .g-text-input__content:hover {
    border-color: #00000026;
}

.custom-input-group:has(.g-button_view_outlined-danger) .g-text-input__content {
    border-color: #ff003dcc;
    border-left: none;
}

.g-text-input .g-text-input__label {
    font-weight: 400;
}

.background-confirmed {
    background-color: #32ba7626;
}

.background-declined {
    background-color: #ff003d26;
}

.success-icon {
    color: var(--g-color-base-positive-heavy);
}

.g-user-label.indicator .g-user-label__avatar {
  position: relative;
}

.g-user-label.indicator .g-user-label__avatar::after {
  content: '';
  position: absolute;
  bottom: 3px;
  right: -3px;
  width: 10px;
  height: 10px;
  background-color: var(--reflection-color);
  border: 2px solid white;
  border-radius: 50%;
}