/* Timeline.module.css */
.timeline {
    display: flex;
    flex-direction: column;

    --border-size: 2px; /* чётное! */
    --border-size-complete: 4px; /* чётное! */

    --svg-size-big: 40px; /* чётное! */
    --svg-size-small: 30px; /* чётное! */

    --distance: 48px;
    --distance-complete: calc(var(--distance) - (var(--border-size-complete) - var(--border-size)) / 2);
    --date-weight: 102px;
    --svg-left: calc(var(--date-weight) + var(--distance) + (var(--border-size) - var(--svg-size-big)) / 2);

    --distance-small: calc((var(--svg-size-small) - var(--border-size)) / 2);
    --distance-complete-small: calc(var(--distance-small) - (var(--border-size-complete) - var(--border-size)) / 2);
    --svg-left-small: calc(-1 * (var(--distance-small) + var(--border-size)));
}

.timeline-item {
    display: flex;
    position: relative;
}

.timeline-item .timeline-icon {
    position: absolute;
    color: var(--gray-color);
    background-color: white;
}

.timeline-delete-button {
    display: none;

    position: absolute;
    top: 50%;
    left: 137px;

    background-color: white;
    color: var(--g-color-base-danger-heavy);
}

.timeline-delete-button.g-button:hover {
    color: var(--g-color-base-danger-heavy);
}

.timeline-draggable-item:hover .timeline-delete-button {
    display: block;
}

.timeline-draggable-item:has(.timeline-delete-button:hover) .timeline-item__description {
    border-left-color: var(--g-color-base-danger-heavy);
}

.timeline-item .timeline-add-button svg {
    color: var(--gray-color);
}

.timeline-item .timeline-add-button {
    position: absolute;
    background-color: white;
    border: none;
    cursor: pointer;
}

.timeline-item .g-button::before {
    background-color: transparent;
}

.timeline-item button .g-button__text {
    padding: 0;
}

.timeline-item-completed .timeline-icon {
    color: var(--main-color);
}

.timeline-item__date {
    display: flex;
    justify-content: flex-start;
    text-align: center;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: bold;
    line-height: 150%;
    min-width: 102px;
}

.timeline-item__date:has(input) {
    display: block;
}

.timeline-item__description {
    padding-bottom: 2.5rem;
}

.timeline-item__description:has(.bmm-text) {
    padding-bottom: 1.5rem;
}

.timeline-item__title {
    color: var(--main-color);
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 130%;
}

.timeline-item__text {
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 150%;
}

@media (min-width: 992px) {
    .timeline-item__date {
        margin-right: var(--distance);
        width: 102px;
        justify-content: center;
    }

    .timeline-item-completed:has(~ .timeline-item-completed) .timeline-item__date {
        margin-right: var(--distance-complete);
    }

    .timeline-item-completed:has(~ .timeline-item-completed) .timeline-item__description {
        padding-left: var(--distance-complete);
    }

    .timeline-item .timeline-icon, .timeline-item .timeline-add-button {
        left: var(--svg-left);
        height: var(--svg-size-big);
        width: var(--svg-size-big);
        top: -8px;
    }

    .timeline-item .timeline-add-button svg {
        height: var(--svg-size-big);
        width: var(--svg-size-big);
    }

    .timeline-item-completed:has(~ .timeline-item-completed) .timeline-item__description {
        border-left: var(--border-size-complete) solid var(--main-color);
    }

    .timeline-item__description {
        padding-left: var(--distance);
        border-left: var(--border-size) dotted var(--gray-color);
    }

    .timeline-item:last-child .timeline-item__description {
        border-left: var(--border-size) solid transparent;
    }
}

@media (max-width: 991px) {
    .timeline-item {
        flex-direction: column;
        padding-left: calc(var(--distance-small) * 2);
        margin-left: var(--distance-small);
        border-left: var(--border-size) dotted var(--gray-color);
    }

    .timeline-item__date:has(input) {
        display: flex;
    }

    .timeline-item-completed:has(~ .timeline-item-completed) {
        border-left: var(--border-size) solid var(--main-color);
    }

    .timeline-item:last-child {
        border-left: var(--border-size) solid transparent;
    }

    .timeline-item .timeline-icon, .timeline-item .timeline-add-button {
        left: var(--svg-left-small);
        height: var(--svg-size-small);
        width: var(--svg-size-small);
    }

    .timeline-delete-button {
        left: -15px;
    }

    .timeline-item .timeline-add-button svg {
        height: var(--svg-size-small);
        width: var(--svg-size-small);
    }

    .timeline-item__title, .timeline-item__text {
        font-size: 1rem;
    }

    .timeline-draggable-item:has(.timeline-delete-button:hover) {
        border-left-color: var(--g-color-base-danger-heavy);
    }
}
