.chat-container {
    display: flex;
    flex-direction: column;
    height: 50vh;
    max-height: 50vh;
    overflow-y: auto;
}

.message-list {
    display: flex;
    flex-direction: column-reverse;
    overflow-y: auto;
    padding: 10px;
    flex-grow: 1;
}

.message {
    display: flex;
    gap: 10px;
    background-color: #f1f1f1;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;
    position: relative;
}

.message-info {
    width: 100%;
}

.message__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.message-author-name {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    font-size: 16px;
}

.message-actions {
    margin-left: 10px;
    display: inline-flex;
    gap: 5px;
}

.message:hover .message-actions {
    display: inline-flex;
}

.message .message-actions {
    display: none;
}

.message-date {
    display: flex;
    justify-content: right;
    font-size: 16px;
    opacity: 0.5;
}

.message .ok {
    display: flex;
    align-items: center;
    color: green;
    margin-left: 10px;
}

.message .not-ok {
    display: flex;
    align-items: center;
    color: red;
    margin-left: 10px;
}

.message-input-container {
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-top: 1px solid #ddd;
    background-color: #fff;
}

.message-input-container textarea {
    width: 100%;
    height: 50px;
    resize: none;
    padding: 10px;
    border: 1px solid #dee2e6;
    border-radius: 6px;
}

.checkbox-container {
    margin: 10px 0;
}

.checkbox-container label {
    margin-right: 10px;
}

.no-message {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: gray;
}