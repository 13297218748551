.user-card {
    color: black;
}

.user-card .user-card__title {
    display: inline-flex;
    align-items: center;
}

.user-card__photo {
    overflow: hidden;
    border-radius: 50%;
    aspect-ratio: 1/1;
    background-color: #D9D9D9;
}

.user-photo {
    aspect-ratio: 1/1;
    object-fit: cover;
    width: 100%;
}

.user-photo-placeholder {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    color: #000000d9;
    background-color: #D9D9D9; /* Серый цвет для placeholder */
    border-radius: 50%;
}

/*.user-card img {*/
/*  border-radius: 50%;*/
/*}*/
.user-card .user-details {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.user-card-right {
    flex-direction: row;
}

.user-card-left .user-card__title {
    flex-direction: row-reverse;
}

.user-card-left .user-details {
    text-align: right;
}

/* size xs */
.user-card-xs .user-card__photo {
    width: 44px;
    height: 44px;
    font-size: 1rem;
}

.user-card-xs .user-details {
    font-size: 13px;
}

.user-card-xs.user-card-right .user-details {
    margin-left: 13px;
}

.user-card-xs.user-card-left .user-details {
    margin-right: 13px;
}

/* size s */
.user-card-s .user-card__photo {
    width: 60px;
    height: 60px;
    font-size: 1.25rem;
}

.user-card-s .user-details {
    font-size: 13px;
}

.user-card-s.user-card-right .user-details {
    margin-left: 13px;
}

.user-card-s.user-card-left .user-details {
    margin-right: 13px;
}

/* size m */
.user-card-m .user-card__photo {
    width: 100px;
    height: 100px;
    font-size: 2rem;
}

.user-card-m .user-details {
    font-size: 26px;
    line-height: 32px;
}

.user-card-m.user-card-right .user-details {
    margin-left: 26px;
}

.user-card-m.user-card-left .user-details {
    margin-right: 26px;
}

/* size l */
.user-card-l .user-card__photo {
    width: 200px;
    height: 200px;
    font-size: 4rem;
}

/* size sp  */
.user-card-sp .user-photo {
    width: 70px;
    height: 70px;
}

.user-card-sp .user-details {
    font-size: 17px;
}

.user-card-sp .user-name {
    font-weight: bold;
    margin-bottom: 8px;
}

.user-card-sp.user-card-right .user-details {
    margin-left: 10px;
}

.user-card-sp.user-card-left .user-details {
    margin-right: 10px;
}

.user-card-sp .user-card__text {
    color: #212529bf;
    max-width: 400px;
    font-size: 15px;
}