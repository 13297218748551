.project-card-public {
    padding: 30px !important;
}

.project-card .project-card__pretitle {
    display: flex;
    justify-content: space-between;
    font-size: 17px;
    font-weight: 500;
    line-height: 1.25rem;
}

.project-card__title {
    color: black;
    display: block;
    font-size: 1.375rem;
    font-weight: 600;
    margin-top: 0.75rem;
    text-decoration: none;
}

.project-card__keywords {
    margin: 0.625rem 0 1rem;
}

.project-card__keywords > div {
    font-size: 14px;
    font-weight: 400;
    line-height: 0.5rem;
    color: black;
    opacity: .5;
    cursor: default;
}

.project-card-draft .project-card__text {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    /*margin-bottom: 2rem;*/
    overflow: hidden;
}

.project-card .group-btn {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

@media screen and (max-width: 800px) {
    .project-card .group-btn .my-button {
        width: 100%;
    }
}

.project-card__subtitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media (max-width: 460px) {
    .project-card-public .project-card__subtitle {
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
    }
}

.project-card__subtitle-date {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 0.5rem;
    opacity: .5;
}

div.g-select-control:has(div.normal) {
    border-radius: var(--g-border-radius-m);
    /*outline: solid 1px rgba(0, 0, 0, 0.29);*/
}

div.g-select-control:has(div.info) {
    background-color: var(--g-color-base-info-light);
    color: var(--g-color-text-info);
    border-radius: var(--g-border-radius-m);
    /*border: solid 1px rgba(0, 0, 0, 0.29);*/
}

div.g-select-control:has(div.warning) {
    background-color: var(--g-color-base-warning-light);
    color: var(--g-color-text-warning);
    border-radius: var(--g-border-radius-m);
    /*outline: solid 1px rgba(0, 0, 0, 0.29);*/
}

div.g-select-control:has(div.danger) {
    background-color: var(--g-color-base-danger-light);
    color: var(--g-color-text-danger);
    border-radius: var(--g-border-radius-m);
    /*border: solid 1px rgba(0, 0, 0, 0.29);*/
}

div.g-select-control:has(div.success) {
    background-color: var(--g-color-base-positive-light);
    color: var(--g-color-text-positive);
    border-radius: var(--g-border-radius-m);
    /*border: solid 1px rgba(0, 0, 0, 0.29);*/
}

.heart-icon-active {
    color: #F1363A;
}

.heart-icon-inactive {
    color: currentColor;
}

.workers-accordion {

}

.workers-accordion .accordion-item {
    background: #f9f9f9;
}

.workers-accordion .accordion-header .accordion-button {
    padding: 10px 0;
    background: #f9f9f9;

    font-weight: 600;
}

.workers-accordion .accordion-body {
    padding-top: 0;
}