.nav-list {
    list-style: none;
    padding: 0 24px 0 0;
    margin: 0;
    width: 100%;
}

.nav-list hr {
    margin: 8px 0;
}

.nav-header {
    padding: 6px 8px;
    font-size: 12px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.5);
}

.nav-item a {
    padding: 6px 8px;
    text-decoration: none;
    color: #000;
    display: flex;
    align-items: center;
    border-radius: 5px;
    transition: background 33.333ms linear;
}

.nav-item-disabled a {
    pointer-events: none;
    color: rgba(0, 0, 0, 0.29);
}

.nav-item a:hover {
    background-color: var(--main-color-light);
}

.nav-item .active {
    position: relative;

    color: #000;
    font-weight: 600;

    background-color: var(--main-color-light);
}

.nav-list_account .nav-item .active:before {
    position: absolute;

    content: "";
    height: 1.5rem;
    left: -0.5rem;
    width: 0.25rem;

    background: var(--main-color-dark);
    border-radius: 0.375rem;
}

.leading-visual {
    display: flex;
    justify-content: center;
    align-items: center;
}

.leading-visual svg {
    height: 16px;
    width: 16px;
    margin-right: 8px;
}

.nav-item-label {
    font-size: 16px;
}

/* Добавляем стили для определения темы "danger" */
.nav-item.danger a,
.nav-item.danger .leading-visual,
.nav-item.danger .nav-item-label {
    /*color: #93211F;*/
    color: var(--g-color-base-danger-heavy);
}

.nav-item.danger a:hover {
    /*background-color: rgba(147, 33, 31, 0.18);*/
    background-color: var(--g-color-base-danger-light);
}

.nav-item.danger .leading-visual svg {
    /*fill: #93211F;*/
    fill: var(--g-color-base-danger-heavy-hover);
}