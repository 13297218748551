html {
    height: 100%;
}

body {
    height: 100%;
    margin: 0;
    /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
    /*  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
    /*  sans-serif;*/
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    --bmm-font-family: 'Fira Sans', sans-serif;
    /*--bmm-font-family: 'Montserrat', sans-serif !important;*/
    font-family: var(--bmm-font-family) !important;

    /*--g-color-text-brand: var(--g-color-private-yellow-700-solid);*/
    /*--g-color-text-brand-heavy: var(--g-color-private-orange-700-solid);*/
    /*--g-color-text-brand-contrast: var(--g-color-text-dark-primary);*/

    /*--g-color-text-link: var(--g-color-private-yellow-650-solid);*/
    /*--g-color-text-link-hover: var(--g-color-private-orange-650-solid);*/

    /*--g-color-base-brand: var(--g-color-private-yellow-550-solid);*/
    /*--g-color-base-brand-hover: var(--g-color-private-yellow-600-solid);*/
    /*--g-color-base-brand-light: var(--g-color-base-warning-light);*/


    --g-color-text-brand: var(--g-color-private-blue-700-solid) !important;
    --g-color-text-brand-heavy: var(--g-color-private-blue-400-solid) !important;;
    --g-color-text-brand-contrast: var(--g-color-text-dark-primary) !important;;

    --g-color-text-link: var(--g-color-private-blue-650-solid) !important;;
    --g-color-text-link-hover: var(--g-color-private-blue-400-solid) !important;;

    --g-color-base-brand: var(--g-color-private-blue-550-solid) !important;;
    --g-color-base-brand-hover: var(--g-color-private-blue-600-solid) !important;;
    --g-color-base-brand-light: var(--g-color-private-blue-100) !important;;


    --fill-color: rgba(51, 51, 51, 0.03);
    /*--main-color: #1F3A93;*/
    --main-color: var(--g-color-base-brand);
    /*--main-color-light: #1f3a932e;*/
    --main-color-light: var(--g-color-base-brand-light);
    /*--main-color-dark: #13245C;*/
    --main-color-dark: var(--g-color-base-brand-hover);
    --red-color: #93211F;
    --red-color-light: #93211F2D;
    --gray-color: #000000a6;

    --reflection-color: var(--g-color-text-link);

    /*--g-popover-max-width: 430px !important;*/

    --g-text-input-border-color: var(--bs-border-color);
    --g-text-input-border-color-active: var(--main-color-light);
    --g-text-input-border-radius: 6px;

    --g-popover-max-width: null;
}

.accordion {
    --bs-accordion-border-radius: 0.9375rem !important;
    --bs-accordion-inner-border-radius: 0.9375rem !important;
}

.accordion-button.collapsed::after, .accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='40' height='40' class='g-icon' fill='currentColor' stroke='none' aria-hidden='true'%3E%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3E%3Cpath fill='currentColor' fill-rule='evenodd' d='M2.97 5.47a.75.75 0 0 1 1.06 0L8 9.44l3.97-3.97a.75.75 0 1 1 1.06 1.06l-4.5 4.5a.75.75 0 0 1-1.06 0l-4.5-4.5a.75.75 0 0 1 0-1.06Z' clip-rule='evenodd'%3E%3C/path%3E%3C/svg%3E%3C/svg%3E");
}

body li > p {
    margin: 0;
}

.root {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

.container {
    flex: 1 1 auto;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.g-spin__inner {
    border-color: var(--main-color-dark);
}

.g-root:not(.g-root_mobile):not(.g-root_native-scrollbar)::-webkit-scrollbar-thumb,
.g-root:not(.g-root_mobile):not(.g-root_native-scrollbar) *::-webkit-scrollbar-thumb {
    border-radius: 6px; /* Радиус скругления бегунка */
}

input, textarea, select, button {
    font-family: inherit !important;
}

a {
    color: var(--g-color-text-link);
}

a:hover{
    color: var(--g-color-text-link-hover);
}

strong { /* пришлось добвить, иначе bootstrap css делает 'boolder', а не 'bold'*/
    font-weight: bold;
}

.mainContainer {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    /*height: 100vh;*/
}

.hidden {
    opacity: 0;
    height: 0;
    min-height: 0 !important;
    width: 0;
    line-height: 0;
    overflow: hidden;
    padding: 0;
    margin: 0;
}

.bmm-card {
    background: #f9f9f9;
    border-radius: 0.9375rem;
    box-sizing: border-box;
    margin: 1.5rem 0;
    padding: 1.0rem;
}

.bmm-card_white {
    background: #ffffff;
}

.bmm-card_white:last-child {
     margin-bottom: 0;
}

li.bmm-card:last-child {
    margin-bottom: 0;
}

.cursor-drag {
    cursor: grab;
}

.reflection-color {
    color: var(--reflection-color);
}

.g-user-label_clickable:hover {
    background-color: unset;
}

.me-10 {
    margin-right: 10px;
}