.footer {
    background: var(--fill-color);

    font-size: 1rem;
    font-weight: 300;
    line-height: 130%;

    margin-top: 3.125rem;
    padding: 1.5rem 0;
}

@media (max-width: 767px) {
    .footer {
        margin-top: 2rem;
    }
}

.footer .row {
    justify-content: space-between;
}

.footer ul {
    padding: 0;
    margin: 0;
    border: none;
}

.footer li:not(:last-child) {
    margin-bottom: 5px;
}

.footer-title {
    font-size: 18px;
    font-weight: 600;
}

.footer-link {
    text-decoration: none;
}

.footer-link_nav {
    color: #000;
}

.logo_block {
    display: flex;
    align-items: center;
    gap: 15px;
}

@media (max-width: 991px) {
    .logo_block {
        justify-content: space-around;
        gap: 20px;
        flex-wrap: wrap;
    }
}

.logo-cmp {
    height: 2rem;
}

.logo-cmp.logo-cmp_short {
    height: 1.5rem;
}

.logo-cmp.logo-cmp_big {
    height: 2.5rem;
}

.logo-cmp img {
    max-height: 100%;
    object-fit: contain;
}