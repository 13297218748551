.header {
    margin-bottom: 3.125rem;
    background: var(--fill-color);
}

@media (max-width: 767px) {
    .header {
        margin-bottom: 2rem;
    }
}

span.navbar-brand {
    height: 60px;
    display: flex;
    align-items: center;
}

.navbar-brand-link {
    font-size: 35px;
    font-weight: 700;
    text-decoration: none;
    color: var(--bs-navbar-brand-color);
}

.nav-link {
    font-size: 18px;
    font-weight: 500;
}

.nav-link_action {
    color: var(--main-color);
}

.navbar-toggler:focus {
    box-shadow: none;
}

.navbar-toggler {
    border: none;
}