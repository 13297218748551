.btn-show-password {
    background-color: white;
    border: var(--bs-btn-border-width) solid var(--bs-border-color);
    --bs-btn-active-bg: white;
    --bs-btn-active-border-color: var(--bs-border-color);
}

.btn-show-password:hover {
    background-color: white;
    border: var(--bs-btn-border-width) solid var(--bs-border-color);
}

.btn-show-password svg{
    color: rgba(0, 0, 0, 0.5);

}

.btn-show-password svg:hover{
    color: black;
}