.ProseMirror:focus {
    outline: none;
}

.tiptap {
    padding: .5rem 0.75rem;
    font-size: 16px;
}

.tiptap-sm .tiptap {
    font-size: 0.875rem !important; /* Пример уменьшенного размера текста */
    padding: 4px 8px;
}

.tiptap > * + * {
    margin-top: 0.75em;
}

.tiptap ul, .tiptap ol {
    padding: 0 1rem;
}

.tiptap h1, .tiptap h2, .tiptap h3, .tiptap h4, .tiptap h5, .tiptap h6 {
    line-height: 1.1;
}

.tiptap code {
    background-color: rgba(97, 97, 97, 0.1);
    color: #616161;
}

.tiptap pre {
    background: #0d0d0d;
    color: #fff;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;
}

.tiptap pre code {
    color: inherit;
    padding: 0;
    background: none;
    font-size: 0.8rem;
}

.tiptap img {
    max-width: 100%;
    height: auto;
}

.tiptap blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(13, 13, 13, 0.1);
}

.tiptap hr {
    border: none;
    border-top: 2px solid rgba(13, 13, 13, 0.1);
    margin: 2rem 0;
}

.text-editor {
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    /*padding: .375rem 0.75rem;*/
}

.text-editor:focus-within {
    border-color: var(--main-color-light) !important;
    box-shadow: 0 0 2px 3px var(--main-color-light) !important;
}

.text-editor:focus-within .text-editor-toolbar {
    border-bottom-color: var(--main-color-light);
}

.text-editor-toolbar {
    border-bottom: var(--bs-border-width) solid var(--bs-border-color);
    padding: .5rem 0.75rem;
    display: flex;
    align-items: center;
    gap: 15px;
}

.text-editor-toolbar.text-editor-toolbar-right {
    justify-content: flex-end;
}

.tiptap-sm .text-editor-toolbar {
    padding: 0.25rem 0.5rem;
}

.text-editor-toolbar-group {
    display: flex;
    align-items: center;
    gap: 5px;
}

.text-editor-toolbar .is-active path {
    fill: var(--main-color) !important;
}

.text-editor-tooltip-hidden {
    display: none;
}